<ng-container
  *ngIf="
    state.isFilling &&
    !state.payerEnroll &&
    !state.showSavedCard &&
    !state.pinSection &&
    !state.verifyingTransfer &&
    !state.error
  "
>
  <form
    autocomplete="off"
    [formGroup]="cardPaymentForm"
    (ngSubmit)="onSubmitBtnClicked()"
  >
    <div class="row pt-4">
      <div class="form-group col-md-12 mb-3">
        <label class="omni-label">Card Number</label>
        <div class="card-number-input">
          <span class="card-type-icon">
            <ng-container [ngSwitch]="cardType">
              <ng-template ngSwitchCase="mastercard">
                <svg-icon
                  name="master-card-icon"
                  svgClass="img-fluid w-30"
                ></svg-icon>
              </ng-template>
              <ng-template ngSwitchCase="visa">
                <svg-icon name="visa-icon" svgClass="img-fluid w-30"></svg-icon>
              </ng-template>
              <ng-template ngSwitchCase="verve">
                <svg-icon
                  name="verve-icon"
                  svgClass="img-fluid w-30"
                ></svg-icon>
              </ng-template>
              <ng-template ngSwitchCase="afrigo">
                <svg-icon
                  name="afrigo-icon"
                  svgClass="img-fluid w-30"
                ></svg-icon>
              </ng-template>
            </ng-container>
          </span>
          <input
            class="omni-form-control card-type cc-number-input"
            formControlName="cardNo"
            name="cardNo"
            type="text"
            id="pan"
            placeholder="5289 8976 8765 7878"
            required
          />
        </div>
      </div>
      <div class="form-group col-md-8 mb-3">
        <label class="omni-label">Expiry (MM/YY)</label>
        <div class="group-input">
          <input
            class="omni-form-control cc-expiry-input"
            maxlength="5"
            formControlName="expiryDate"
            name="expiryDate"
            id="exp"
            type="text"
            placeholder="MM/YY"
            required
          />
        </div>
      </div>
      <div class="form-group col-md-4 mb-3">
        <label class="omni-label">CVV</label>
        <div class="group-input">
          <input
            (keypress)="checkDigit($event)"
            class="omni-form-control"
            maxlength="3"
            formControlName="cvv"
            id="cvv"
            name="cvv"
            type="password"
            placeholder="XXX"
            required
          />
        </div>
      </div>
      <div class="form-group col-md-12 mb-3" *ngIf="showPin">
        <label class="omni-label">Pin</label>
        <div class="group-input">
          <input
            (keypress)="checkDigit($event)"
            class="omni-form-control"
            maxlength="6"
            formControlName="pin"
            id="pin"
            name="pin"
            type="password"
            placeholder=""
            required
          />
        </div>
      </div>
      <div class="form-group col-md-12 mb-3">
        <div class="omni-checkbox">
          <input
            type="checkbox"
            class="omni-input"
            id="omni-check"
            (change)="saveCard($event)"
          />
          <label class="omni-label" for="omni-check"
            >Save card information</label
          >
        </div>
      </div>
      <div class="form-group col-md-12 mt-2">
        <div class="group-input">
          <button
            class="omni-btn omni-btn-primary omni-btn-block"
            type="submit"
            [disabled]="!cardPaymentForm.valid"
            [ngClass]="{ 'omni-btn-loading': loading }"
          >
            <span
              >Pay
              {{
                amount
                  ? (amount
                    | currency : transactionData.currency : "symbol-narrow")
                  : ""
              }}</span
            >
          </button>
          <button
            *ngIf="hasSavedCard"
            (click)="fetchSavedCards()"
            class="omni-btn omni-btn-transparent text-primary"
            type="button"
          >
            Pay with a saved card
          </button>
          <span></span>
        </div>
      </div>
    </div>
  </form>
</ng-container>
<ng-container
  *ngIf="
    state.showSavedCard &&
    !state.pinSection &&
    state.isFilling &&
    !state.verifyingTransfer &&
    !state.error
  "
>
  <div class="col-xs-12 col-md-12">
    <div class="form-group">
      <label class="omni-label">Your saved cards</label>
      <div
        class="content-card pay-type"
        *ngFor="let card of cards; let i = index"
      >
        <div class="card-body p-0">
          <div class="me-3">
            <div class="mcard-label__type">
              <ng-container [ngSwitch]="card.cardType">
                <ng-template ngSwitchCase="MASTERCARD">
                  <svg-icon
                    name="master-card-icon"
                    svgClass="img-fluid w-30"
                  ></svg-icon>
                </ng-template>
                <ng-template ngSwitchCase="VISA">
                  <svg-icon
                    name="visa-icon"
                    svgClass="img-fluid w-30"
                  ></svg-icon>
                </ng-template>
                <ng-template ngSwitchCase="VERVE">
                  <svg-icon
                    name="verve-icon"
                    svgClass="img-fluid w-30"
                  ></svg-icon>
                </ng-template>
                <ng-template ngSwitchCase="AFRIGO">
                  <svg-icon
                    name="afrigo-icon"
                    svgClass="img-fluid w-30"
                  ></svg-icon>
                </ng-template>
              </ng-container>
            </div>
          </div>
          <div>
            <span class="card-number">
              {{ card.cardPan }}
            </span>
            <!-- <p class="card-name">
              {{ card.cardName }}
            </p> -->
          </div>
        </div>
        <span class="pay-type-select">
          <span>
            <input
              class="omni-input"
              [(ngModel)]="paymentCard"
              [value]="card"
              type="radio"
              [checked]="cardChecked"
              name="paymentCard"
              [id]="'pay-card' + i"
              (change)="cardSelected($event)"
            />
            <label [for]="'pay-card' + i"></label>
          </span>
        </span>
      </div>
    </div>
    <div class="form-button mt-4">
      <button
        class="omni-btn omni-btn-primary"
        type="submit"
        (click)="confirmPayment()"
      >
        Proceed
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="state.pinSection && !state.verifyingTransfer">
  <form class="payment-form px-2" (ngSubmit)="onVerify()">
    <ng-container>
      <div class="pt-5">
        <div class="payment-form-title">
          <small class="pb-1"> Enter the OTP code that has been sent to </small>
          <div class="psub-title">
            your Email <span style="color: #677684">and</span> Phone Number.
          </div>
        </div>

        <div class="form-group mb-2 mt-4">
          <app-otp-input
            [length]="6"
            [inputClass]="'otp-input'"
            (otpChange)="onOtpChange($event)"
          ></app-otp-input>
        </div>

        <div class="form-button mt-4">
          <button id="elem" class="omni-btn omni-btn-primary" type="submit">
            Verify now
          </button>
        </div>

        <div class="w-full text-center pt-4">
          <div style="color: #677684" class="fz-14">
            OTP will expire in
            <span *ngIf="display; else loading">{{ display }}</span>
            <ng-template #loading> Loading... </ng-template>
          </div>

          <!-- <a class="fz-14 code-resend-link"> Resend Now </a> -->
        </div>
      </div>
    </ng-container>
  </form>
</ng-container>
<ng-container *ngIf="state.verifyingTransfer">
  <div class="verification-loader">
    <span class="omni-spinner-text">Please wait</span>
    <div class="omni-spinner-border" role="status"></div>
  </div>
</ng-container>
<ng-container>
  <iframe
    frameborder="0"
    id="threedscardinalTarget"
    style="display: none"
  ></iframe>
</ng-container>
<ng-container *ngIf="state.error">
  <div class="omni-checkout-alert">
    <div class="text-center">
      <div class="custom-alert-icon mb-3">
        <svg-icon name="error-icon" svgClass="img-fluid w-50"></svg-icon>
      </div>
      <div class="px-3">
        <div class="fz-13 fw-bold pb-2">
          We could not process this transaction
        </div>
        <div class="w-full text-center pb-2 lh-2">
          <small class="fz-12">
            {{ errorMessage }}
          </small>
        </div>
      </div>
      <!-- <div class="mt-4">
        <button (click)="tryAgain()" class="omni-btn omni-btn-transparent">Try again</button>
      </div> -->
    </div>
  </div>
</ng-container>

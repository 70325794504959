<ng-container
  *ngIf="!state.verifyingGoogle && !state.error && !state.pinSection"
>
  <div class="outer">
    <div class="inner">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 57 56"
          fill="none"
        >
          <circle cx="28.5" cy="28" r="28" fill="#EAECF0" />
          <path
            d="M37.8617 17H19.1383C13.2872 17 8.5 21.7872 8.5 27.6383C8.5 33.4894 13.2872 38.2766 19.1383 38.2766H37.8617C43.7128 38.2766 48.5 33.4894 48.5 27.6383C48.5 21.7872 43.7128 17 37.8617 17Z"
            fill="white"
          />
          <path
            d="M37.8617 17.8617C39.1755 17.8617 40.4521 18.1223 41.6543 18.633C42.8191 19.1277 43.8617 19.8351 44.766 20.734C45.6649 21.633 46.3723 22.6809 46.867 23.8457C47.3777 25.0479 47.6383 26.3245 47.6383 27.6383C47.6383 28.9521 47.3777 30.2287 46.867 31.4309C46.3723 32.5957 45.6649 33.6383 44.766 34.5426C43.867 35.4415 42.8191 36.1489 41.6543 36.6436C40.4521 37.1543 39.1755 37.4149 37.8617 37.4149H19.1383C17.8245 37.4149 16.5479 37.1543 15.3457 36.6436C14.1809 36.1489 13.1383 35.4415 12.234 34.5426C11.3351 33.6436 10.6277 32.5957 10.133 31.4309C9.62234 30.2287 9.3617 28.9521 9.3617 27.6383C9.3617 26.3245 9.62234 25.0479 10.133 23.8457C10.6277 22.6809 11.3351 21.6383 12.234 20.734C13.133 19.8351 14.1809 19.1277 15.3457 18.633C16.5479 18.1223 17.8245 17.8617 19.1383 17.8617H37.8617ZM37.8617 17H19.1383C13.2872 17 8.5 21.7872 8.5 27.6383C8.5 33.4894 13.2872 38.2766 19.1383 38.2766H37.8617C43.7128 38.2766 48.5 33.4894 48.5 27.6383C48.5 21.7872 43.7128 17 37.8617 17Z"
            fill="#101828"
          />
          <path
            d="M27.575 28.3947V31.6128H26.5537V23.666H29.2612C29.9473 23.666 30.5324 23.8947 31.0112 24.3522C31.5005 24.8096 31.7452 25.3681 31.7452 26.0277C31.7452 26.7033 31.5005 27.2618 31.0112 27.7139C30.5378 28.166 29.9526 28.3894 29.2612 28.3894H27.575V28.3947ZM27.575 24.6447V27.416H29.2824C29.6867 27.416 30.0271 27.2777 30.2931 27.0064C30.5644 26.7352 30.7026 26.4054 30.7026 26.033C30.7026 25.666 30.5644 25.3415 30.2931 25.0703C30.0271 24.7884 29.692 24.6501 29.2824 24.6501H27.575V24.6447Z"
            fill="#101828"
          />
          <path
            d="M34.4153 25.9961C35.1706 25.9961 35.7664 26.1982 36.2025 26.6025C36.6387 27.0067 36.8568 27.5599 36.8568 28.2621V31.6131H35.8834V30.8578H35.8408C35.4206 31.4801 34.8568 31.7886 34.1547 31.7886C33.5536 31.7886 33.0536 31.6131 32.6494 31.2567C32.2451 30.9003 32.043 30.4589 32.043 29.9269C32.043 29.3631 32.2557 28.9163 32.6813 28.5865C33.1068 28.2514 33.6759 28.0865 34.3834 28.0865C34.9898 28.0865 35.4898 28.1982 35.8781 28.4216V28.1876C35.8781 27.8312 35.7398 27.5333 35.4579 27.2833C35.1759 27.0333 34.8462 26.911 34.4685 26.911C33.8994 26.911 33.4472 27.1503 33.1174 27.6344L32.2185 27.0706C32.7132 26.3525 33.4472 25.9961 34.4153 25.9961ZM33.0962 29.9429C33.0962 30.2089 33.2079 30.4323 33.4366 30.6078C33.66 30.7833 33.9259 30.8738 34.2291 30.8738C34.66 30.8738 35.043 30.7142 35.3781 30.395C35.7132 30.0759 35.8834 29.7035 35.8834 29.2727C35.5642 29.0227 35.1228 28.895 34.5536 28.895C34.1387 28.895 33.793 28.9961 33.5164 29.1929C33.2345 29.4003 33.0962 29.6503 33.0962 29.9429Z"
            fill="#101828"
          />
          <path
            d="M42.4108 26.1719L39.0065 34.0017H37.9533L39.2193 31.2623L36.9746 26.1719H38.0863L39.7033 30.0761H39.7246L41.2991 26.1719H42.4108Z"
            fill="#101828"
          />
          <path
            d="M23.5124 27.7451C23.5124 27.4121 23.4827 27.0935 23.4273 26.7871H19.1465V28.5424L21.6119 28.543C21.5119 29.127 21.1901 29.6249 20.697 29.9568V31.0956H22.1646C23.0215 30.3025 23.5124 29.1302 23.5124 27.7451Z"
            fill="#4285F4"
          />
          <path
            d="M20.6975 29.9578C20.289 30.2334 19.763 30.3945 19.1475 30.3945C17.9587 30.3945 16.9502 29.5935 16.589 28.5137H15.0752V29.6881C15.8252 31.1764 17.3667 32.1977 19.1475 32.1977C20.3784 32.1977 21.4124 31.7929 22.1651 31.0961L20.6975 29.9578Z"
            fill="#34A853"
          />
          <path
            d="M16.4467 27.642C16.4467 27.3388 16.4972 27.0457 16.5892 26.7702V25.5957H15.0754C14.7653 26.2111 14.5908 26.9058 14.5908 27.642C14.5908 28.3782 14.7658 29.0728 15.0754 29.6883L16.5892 28.5138C16.4972 28.2383 16.4467 27.9452 16.4467 27.642Z"
            fill="#FABB05"
          />
          <path
            d="M19.1475 24.8891C19.8193 24.8891 20.4209 25.1205 20.8959 25.5726L22.1965 24.2732C21.4066 23.5375 20.3768 23.0859 19.1475 23.0859C17.3672 23.0859 15.8252 24.1072 15.0752 25.5955L16.589 26.77C16.9502 25.6902 17.9587 24.8891 19.1475 24.8891Z"
            fill="#E94235"
          />
        </svg>
      </div>
      <span class="text">Pay with Google pay</span>
      <span class="instruction">Click the button below to start payment</span>
    </div>
  </div>

  <div class="button">
    <google-pay-button
      environment="PRODUCTION"
      buttonSizeMode="fill"
      buttonType="pay"
      [paymentRequest]="paymentRequest"
      (loadpaymentdata)="onLoadPaymentData($event)"
      (error)="onError($event)"
    ></google-pay-button>
  </div>
</ng-container>
<ng-container *ngIf="state.pinSection && !state.verifyingGoogle">
  <form class="payment-form px-2" (ngSubmit)="onVerify()">
    <ng-container>
      <div class="pt-5">
        <div class="payment-form-title">
          <small class="pb-1"> Enter the OTP code that has been sent to </small>
          <div class="psub-title">
            your Email <span style="color: #677684">and</span> Phone Number.
          </div>
        </div>

        <div class="form-group mb-2 mt-4">
           <app-otp-input
            [length]="6"
            [inputClass]="'otp-input'"
            (otpChange)="onOtpChange($event)"
          ></app-otp-input>
          <!-- <div class="group-input">
            <ngx-otp-input
              [config]="otpInputConfig"
              (otpChange)="setOTP($event)"
              class="mt-3"
              #otp
            >
            </ngx-otp-input>
          </div> -->
        </div>

        <div class="form-button mt-4">
          <button id="elem" class="omni-btn omni-btn-primary" type="submit">
            Verify now
          </button>
        </div>

        <div class="w-full text-center pt-4">
          <div style="color: #677684" class="fz-14">
            OTP will expire in
            <span *ngIf="display; else loading">{{ display }}</span>
            <ng-template #loading> Loading... </ng-template>
          </div>

          <!-- <a class="fz-14 code-resend-link"> Resend Now </a> -->
        </div>
      </div>
    </ng-container>
  </form>
</ng-container>
<ng-container *ngIf="state.verifyingGoogle">
  <div class="verification-loader">
    <span class="omni-spinner-text">{{ loadingMessage }}</span>
    <div class="omni-spinner-border" role="status"></div>
  </div>
</ng-container>
<ng-container>
  <iframe
    frameborder="0"
    id="googleCardinalTarget"
    style="display: none"
  ></iframe>
</ng-container>
<ng-container *ngIf="state.error">
  <div class="omni-checkout-alert">
    <div class="text-center">
      <div class="custom-alert-icon mb-3">
        <svg-icon name="error-icon" svgClass="img-fluid w-50"></svg-icon>
      </div>
      <div class="px-3">
        <div class="fz-14 fw-bold pb-2">
          We could not process this transaction
        </div>
        <div class="w-full text-center pb-2 lh-2">
          <small class="fz-12">
            {{ errorMessage }}
          </small>
        </div>
      </div>
      <!-- <div class="mt-4">
                            <button (click)="tryAgain()" class="omni-btn omni-btn-transparent">Try again</button>
                          </div> -->
    </div>
  </div>
</ng-container>

<div class="otp-container">
  <input
    *ngFor="let digit of otpArray; let i = index"
    type="text"
    maxlength="1"
    [class]="inputClass"
    [(ngModel)]="otp[i]"
    (input)="onOtpInput($event, i)"
    (keydown.backspace)="onOtpBackspace($event, i)"
    (paste)="onOtpPaste($event)"
  />
</div>

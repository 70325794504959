import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { TransactionData } from '../api-request';
import { ApiResponse, TransactionFees } from '../api-response';
import { AppService } from '../app.service';
import { StringHelper } from '../strings-helper';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { environment } from 'src/environments/environment';
import { postTransactionMessage } from '../events';
@Component({
  selector: 'google-channel',
  templateUrl: './google-pay.component.html',
  styleUrls: ['./google-pay.component.scss'],
})
export class GooglePayComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Output() onComplete: any = new EventEmitter<any>();
  @Output() onClose: any = new EventEmitter<any>();
  @Output() onRedirect: any = new EventEmitter<any>();
  @Input() activeTab: any = null;
  error: boolean = false;
  queryTime: number = 60;
  isIntervalActive: boolean = true;
  GOOGLE_ID: string = environment.GOOGLE_PAY_ID;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  otpCode!: string;

  display: string = `${5} minutes`;
  time_in_minutes = 5;

  state = {
    visible: false,
    completed: false,
    loaded: false,
    verifyingGoogle: false,
    error: false,
    pinSection: false,
  };
  responseData: any;
  transactionData!: TransactionData;
  amount: any;
  transactionFees!: TransactionFees;
  reference!: string;
  errorMessage!: string;
  token: any;
  trustedHTML: any;
  public timerInterval: any;
  @ViewChild('myDiv', { static: false }) myDiv!: ElementRef;

  loadingMessage: string = 'Please wait';
  paymentRequest!: google.payments.api.PaymentDataRequest;
  cardinalCallbackUrl: string = 'https://zestpayment.com/loader.html';
  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  private timer$: Subscription | undefined;

  constructor(
    private cd: ChangeDetectorRef,
    private appService: AppService,
    private el: ElementRef,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.state.visible) {
      if (changes['activeTab'].currentValue == 'google-tab') {
      }
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.appService.getData().subscribe({
      next: (res) => {
        this.transactionData = res.txnData;
        this.reference = res.txnRef;
        this.transactionFees = res.transactionFees;
        this.amount = StringHelper.toMajorAmount(
          +this.transactionData.amount + this.transactionFees.WEB_CARD
        );
        this.paymentRequest = {
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['MASTERCARD', 'VISA'],
              },
              tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                  gateway: 'zestpayment',
                  gatewayMerchantId: this.transactionData.key,
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: this.GOOGLE_ID + '',
          },
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: this.amount + '',
            currencyCode: this.transactionData.currency,
            countryCode: 'NG',
          },
        };
      },
      error: (err) => {
        console.log(err);
      },
    });

  }
 onOtpChange(otp: string): void {
    this.otpCode = otp; // Update the OTP value
 }

  checkIframeContentChange() {
    // detecting if iframe URL has changed
    console.log('Content changed!');
    this.cd.detectChanges();
    this.confirmTransaction();
  }

  onLoadPaymentData(event: Event) {
    const paymentData = (event as CustomEvent<google.payments.api.PaymentData>)
      .detail;
    this.token = paymentData.paymentMethodData.tokenizationData.token;

    this.setState('verifyingGoogle', true);
    const data = {
      cardRequestType: 'INITIATE_CARD_PAYMENT',
      email: this.transactionData.email,
      transactionRef: this.reference,
      currency: this.transactionData.currency,
      encryptedMessage: this.token,
      cardPlatform: 'GOOGLE',
    };
    this.appService.initiateCardPayment(data).subscribe({
      next: (res) => {
        if (res.success) {
          this.setState('verifyingGoogle', false);
          switch (res.data.code) {
            case '00':
              this.paymentDone();
              break;
            case '01':
              this.setState('pinSection', true);
              break;
            case '02':
              // this.openVisaForm(res.data);
              break;
            case '03':
              this.authenticateMasterCardPayment(data);
              break;
            default:
              this.errorMessage =
                'We are unable to complete your request at this time, Please try again later';
              this.setState('error', true);
              break;
          }
        } else {
          this.errorMessage = StringHelper.getErrorMessage(res);
          this.setState('verifyingGoogle', false);
          this.setState('error', true);
          this.paymentFailed();
        }
      },
      error: (err) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        // this.loading = false;
        this.setState('verifyingGoogle', false);
        this.setState('error', true);
        this.paymentFailed();
      },
    });
  }

  onError = (event: ErrorEvent): void => {
    console.error('error', event.error);
  };

  authenticateMasterCardPayment(authData: any) {
    this.setState('verifyingGoogle', true);
    const data = {
      cardRequestType: 'AUTHENTICATE_PAYER',
      transactionRef: this.reference,
      encryptedMessage: this.token,
      cardPlatform: 'GOOGLE',
      browser: navigator.userAgent,
      threeDSecureChallengeWindowSize: '390_X_400',
      acceptHeaders:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
      colorDepth: screen.pixelDepth,
      javaEnabled: navigator.javaEnabled() ? true : false,
      javaScriptEnabled: true,
      language: navigator.language,
      screenHeight: screen.height,
      screenWidth: screen.width,
      timeZone: new Date().getTimezoneOffset(),
    };

    setTimeout(() => {
      this.continueAuthentication(data);
    }, 10000);
  }

  continueAuthentication(data: any) {
    this.appService.authenticateCardPayment(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.openMasterCardForm(res.data);
        } else {
          this.errorMessage = StringHelper.getErrorMessage(res);
          this.setState('verifyingGoogle', false);
          this.setState('error', true);
          this.paymentFailed();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingGoogle', false);
        this.setState('error', true);
        this.paymentFailed();
      },
    });
  }

  openMasterCardForm(data: any) {
    this.setState('verifyingGoogle', false);
    this.onRedirect.emit();
    this.trustedHTML = this.sanitizer.bypassSecurityTrustHtml(
      data.redirectHtml
    );
    const iframe = document.getElementById(
      'googleCardinalTarget'
    ) as HTMLIFrameElement;
    iframe.style.cssText =
      'display: block;z-index: 999999999999999;background: #FFF;border: 0px none transparent;overflow-x: hidden;overflow-y: hidden;margin: 0;padding: 0;-webkit-tap-highlight-color: transparent;-webkit-touch-callout: none;position: fixed;left: 0;top: 0;width: 100%;height: 100%';
    iframe.src = 'about:blank';

    const handleLoad = () => {
      iframe.removeEventListener('load', handleLoad); // Remove the event listener after it fires
      this.checkIframeContentChange(); // detecting if iframe content has changed
    };

    iframe.addEventListener('load', handleLoad);

    const iWindow = iframe.contentWindow;
    iWindow?.document.open();
    iWindow?.document.write(data.redirectHtml);
    iWindow?.document.close();
    this.setState('loaded', true);
  }

  removeIFrame() {
    const frame = document.getElementById(
      'googleCardinalTarget'
    ) as HTMLIFrameElement;
    frame.parentNode?.removeChild(frame);
  }

  // confirmTransaction() {
  //   this.setState('verifyingGoogle', true);
  //   const data = {
  //     cardRequestType: 'INITIATE_CARD_PAYMENT',
  //     email: this.transactionData.email,
  //     transactionRef: this.reference,
  //     currency: this.transactionData.currency,
  //     encryptedMessage: this.token,
  //   };

  //   this.appService.queryUssdTransaction(data).subscribe({
  //     next: (res: ApiResponse) => {
  //       if (res.success) {
  //         this.setState('verifyingGoogle', false);
  //         this.paymentDone();
  //       } else {
  //         const errorMessage = StringHelper.getErrorMessage(res);
  //         if (errorMessage == 'PENDING_CONFIRMATION') {
  //           this.beginQuery();
  //           return;
  //         }
  //         this.setState('verifyingGoogle', false);
  //       }
  //     },
  //     error: (err) => {
  //       this.errorMessage = StringHelper.getErrorMessage(err.error);
  //       this.setState('verifyingGoogle', false);
  //       this.setState('error', true);
  //     },
  //   });
  // }

  confirmTransaction() {
    this.setState('verifyingGoogle', true);
    const data = {
      cardRequestType: 'CARD_PAYMENT_STATUS',
      transactionRef: this.reference,
    };
    this.appService.confirmCardPayment(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          if (
            res.data.narration == 'IN_PROGRESS' ||
            res.data.statusCode == '90'
          ) {
            this.beginQuery();
            return;
          } else {
            this.setState('verifyingGoogle', false);
            this.removeIFrame();
            this.paymentDone();
          }
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingGoogle', false);
        this.setState('error', true);
        this.paymentFailed();
      },
    });
  }

  beginQuery() {
    this.startInterval();
  }

  startInterval() {
    this.timer$ = timer(0, 10000).subscribe((tick: any) => {
      if (!this.isIntervalActive) {
        // Stop further execution if the interval is not active
        return;
      }

      if (tick == this.queryTime) {
        this.errorMessage = `We could not confirm your payment.`;
        console.log(this.errorMessage);

        setTimeout(() => {
          this.setState('verifyingGoogle', false);
          this.setState('error', true);
          this.paymentFailed();
        }, 5000);
      } else if (tick == this.queryTime + 2) {
        this.stopInterval();
        this.removeIFrame();
      } else {
        // Your API call, which will be performed every 10000 milliseconds
        // Approximately 20 minutes and 20 seconds
        this.queryTransaction();
      }
    });
  }

  stopInterval() {
    this.isIntervalActive = false;

    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  }

  queryTransaction() {
    const data = {
      cardRequestType: 'CARD_PAYMENT_STATUS',
      transactionRef: this.reference,
    };
    this.appService.confirmCardPayment(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          if (
            res.data.narration == 'IN_PROGRESS' ||
            res.data.statusCode == '90'
          ) {
            return;
          }
          else if (res.data.statusCode == '99') {
            this.errorMessage = StringHelper.getErrorMessage(res.data.narration);
            this.setState('verifyingGoogle', false);
            this.setState('error', true);
            this.removeIFrame();
            this.stopInterval();

          } else {
            this.setState('verifyingGoogle', false);
            this.removeIFrame();
            this.paymentDone();
            this.stopInterval();
          }
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingGoogle', false);
        this.setState('error', true);
        this.removeIFrame();
        this.stopInterval();
        this.paymentFailed();
      },
    });
  }

  onVerify() {
    this.setTransaction();
  }

  setOTP(otp: string[]) {
    this.otpCode = otp.join('');
  }

  setTransaction() {
    this.setState('verifyingGoogle', true);

    const data = {
      cardRequestType: 'VALIDATE_CARD_OTP',
      otp: this.otpCode,
      transactionRef: this.reference,
      cardPlatform: 'GOOGLE',
    };

    // if (this.cardType == 'afrigo') {
    //   data['cardRequestType'] = 'PAYMENT_CONFIRMATION';
    // } else {
    //   data['cardRequestType'] = 'VALIDATE_CARD_OTP';
    // }

    this.appService.authenticateCardPayment(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.responseData = res.data;
          this.setState('verifyingGoogle', false);
          this.paymentDone();
        }
      },
      error: (err: any) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('pinSection', false);
        this.setState('verifyingGoogle', false);
        this.setState('error', true);
        this.paymentFailed();
      },
    });
  }

  formatTime(value: number) {
    `${Math.floor(value / 60)}:${('0' + (value % 60)).slice(-2)}`;
  }

  startCountDown() {
    this.timer(this.time_in_minutes);
  }

  stopCountDown() {
    clearInterval(this.timerInterval);
  }

  timer(minute: number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  reInitialize() {
    this.stopCountDown();
    setTimeout(() => {
      this.startCountDown();
    }, 1000);
  }

  cancelProcess() {
    this.setState('verifyingGoogle', false);
    this.setState('error', false);
  }

  paymentDone() {
    this.onComplete.emit();
    const domEvent = new CustomEvent('paymentDone');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  paymentFailed() {
    const error = {
      reference: this.reference,
      message: this.errorMessage
    };
    postTransactionMessage('error', error);
    const domEvent = new CustomEvent('paymentFailed');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
    //  this.scriptLoaded$.complete();
  }
}

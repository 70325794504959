export const environment = {
	production: false,
	secretKey: "C432A7D6C6BDAB31",
	GOOGLE_PAY_ID: "BCR2DN4TZL3POBI",
	APPLE_PAY_ID: "PGW-ZP-101",
	initiativeContext: "dev.zestpayment.com",
	BASE_URL: "https://api.dev.gateway.zestpayment.com/payment-engine",
	VAS_SOCKET_URL: "https://api.dev.gateway.zestpayment.com/virtual-account",
	debug: true,
	NETHONE_SCRIPT_URL:
		"https://d2sytucvh9dcel.cloudfront.net/s/125511/dVrOny.js",
	X_CSCAPI_KEY: "bFhlRVExa2lvY0tCN0k0TXV6OUhHRFIwWmVrOUw2THVDOVRwSHZzOA==",
	txnQueryTime: 60, // 20 minutes
};

import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {
 @Input() length: number = 6; // Default OTP length
  @Input() inputClass: string = 'otp-input'; // Class for styling
  @Output() otpChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() otpComplete: EventEmitter<string> = new EventEmitter<string>();

  otp: string[] = [];
  otpArray: string[] = [];
  constructor() { }

  ngOnInit(): void {
     this.otp = new Array(this.length).fill('');
    this.otpArray = Array.from(this.otp);
  }



  onOtpInput(event: any, index: number): void {
    const input = event.target;
    const value = input.value;

    // Allow only digits
    if (!/^\d$/.test(value)) {
      input.value = '';
      return;
    }

    this.otp[index] = value;

    // Focus next input
    const nextInput = input.nextElementSibling;
    if (nextInput) {
      nextInput.focus();
    }

    this.emitOtpChange();
  }

   onOtpBackspace(event: any, index: number): void {
    const input = event.target;

    if (!input.value && index > 0) {
      this.otp[index] = '';
      const prevInput = input.previousElementSibling;
      if (prevInput) {
        prevInput.focus();
      }
    }

    this.emitOtpChange();
   }
 onOtpPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pasteData = event.clipboardData?.getData('text') || '';
    const validData = pasteData.slice(0, this.length);

    // Populate inputs
    validData.split('').forEach((char, index) => {
      if (/^\d$/.test(char)) {
        this.otp[index] = char;
      }
    });

    this.emitOtpChange();
 }

  private emitOtpChange(): void {
    const otpString = this.otp.join('');
    this.otpChange.emit(otpString);

    // Emit complete event if all fields are filled
    if (otpString.length === this.length && !this.otp.includes('')) {
      this.otpComplete.emit(otpString);
    }
  }
}
